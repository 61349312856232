<template>
  <v-card elevation="0" class="mx-0" outlined>
    <v-card-title class="border-bottom pb-0">
      <div
        class="align-baseline justify-space-between col"
        :class="{ 'd-flex': !mobile }"
      >
        <div>{{ $route.name }}</div>
        <v-btn
          color="primary"
          class="mb-3 align-self-right"
          elevation="0"
          outlined
          @click="newSite($route.params.study)"
          :block="mobile"
        >
          {{ $t("createSite") }}
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <site-list></site-list>
    </v-card-text>
  </v-card>
</template>

<script>
import SiteList from "../components/admin/SiteList.vue";
export default {
  methods: {
    newSite() {
      this.$router.push({
        name: "Admin create site"
      });
    }
  },
  components: { SiteList },
  i18n: {
    messages: {
      en: {
        createSite: "Add Site"
      },
      fr: {
        createSite: "Ajouter un Centre"
      }
    }
  }
};
</script>
