<template>
  <div>
    <v-data-table
      id="siteList"
      :headers="headers"
      :items="items"
      mobile-breakpoint="800"
      class="my-5 mx-3"
      :search="search"
      item-key="siteCode"
      show-expand
      single-expand
    >
      <template v-slot:top="{}">
        <div
          class="align-baseline col justify-space-between pb-0"
          :class="{ 'd-flex': !mobile, 'px-0': mobile }"
        >
          <h5 class="ml-2">{{ $t("sitesList") }}</h5>
          <v-spacer></v-spacer>
          <v-text-field
            outlined
            id="user-search"
            v-model="search"
            :label="$t('sitesSearch')"
            class="mw-50"
            clearable
          ></v-text-field>
        </div>
        <div class="d-inline-flex align-center">
          <div class="mx-3">{{ $t("allFrozen") }}</div>
          <v-switch
            id="freeze-all"
            :data-frozen="String(studyFrozen)"
            :value="studyFrozen"
            :disabled="studyFrozen"
            @change="freezeStudy()"
          ></v-switch>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <text-input v-model="item.name"> </text-input>
      </template>
      <template v-slot:[`item.frozen`]="{ item }">
        <v-switch
          :id="`freeze-${item.siteCode}`"
          :data-frozen="String(item.frozen)"
          v-model="item.frozen"
        ></v-switch>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <text-input :label="$t('address')" v-model="item.address">
          </text-input>
        </td>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div class="text-right">
      <div class="my-3 text-right">
        <action-panel
          @action="onAction($event)"
          :saveDisabled="!stale"
        ></action-panel>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../input/TextInput.vue";
import ActionPanel from "../admin/ActionPanelAdmin.vue";

export default {
  data() {
    return {
      search: "",
      sites: [],
      headers: [
        { text: this.$t("siteCode"), value: "siteCode" },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("frozen"), value: "frozen" },
        { text: "", value: "data-table-expand" }
      ],
      items: []
    };
  },
  computed: {
    stale() {
      return this.sites.some((s, x) => s.update(this.items[x]) != s);
    },
    studyFrozen() {
      return this.items.every(s => s.frozen);
    }
  },
  methods: {
    async loadSites() {
      this.sites = await this.drivers.siteDriver.getAll(this.currentStudy);
      this.items = this.sites.map(s => {
        return {
          name: s.name,
          siteCode: s.siteCode,
          address: s.address,
          frozen: s.frozen
        };
      });
    },
    async saveSites() {
      await Promise.all(
        this.sites.map(async (s, i) => {
          const updated = s.update(this.items[i]);
          const keys = await this.drivers.siteDriver.save(
            this.currentStudy,
            updated
          );
          this.sites[i] = updated.update(keys);
        })
      );
      await this.loadSites();
    },
    freezeStudy() {
      this.items = this.items.map(i => ({ ...i, frozen: true }));
    },
    async onAction({ type }) {
      switch (type) {
        case "cancel":
          await this.loadSites();
          break;
        case "save":
          await this.saveSites();
          break;
      }
    }
  },
  async mounted() {
    await this.loadSites();
  },
  inject: ["needSave"],
  watch: {
    stale(value) {
      this.needSave(value);
    }
  },
  i18n: {
    messages: {
      en: {
        createSite: "New Site",
        name: "Name",
        address: "Address",
        siteCode: "Site Code",
        sitesList: "Sites list",
        sitesSearch: "Sites search",
        frozen: "Frozen",
        allFrozen: "Study frozen"
      },
      fr: {
        createSite: "Nouveau Centre",
        name: "Nom",
        address: "Adresse",
        siteCode: "N° centre",
        sitesList: "Liste des centres",
        sitesSearch: "Recherche de centre",
        frozen: "Gelé",
        allFrozen: "Etude gelée"
      }
    }
  },
  components: {
    TextInput,
    ActionPanel
  }
};
</script>

<style>
#siteList tr.v-data-table__expanded {
  box-shadow: none;
  background: rgba(0, 129, 255, 0.1);
}
#siteList tr.v-data-table__expanded__row:hover {
  background: rgba(0, 129, 255, 0.1) !important;
}
#siteList tr.v-data-table__expanded__row td {
  border-bottom: none !important;
}
#siteList tr.v-data-table__expanded__content td {
  border-top: none !important;
  padding-bottom: 1em;
}
</style>
